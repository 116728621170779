import ClubTeamResults from "@/models/sportevent/ClubTeamResults";
import BaseService from "../BaseService";
import HttpService from "../HttpService";
import localstore_sportevent from "../../store/localstore_sportevent.module";

class ClubTeamResultService extends BaseService<ClubTeamResults> {
  public url: string;

  constructor(url = "sportevent/club-team-results/") {
    super(url, "");
    this.url = url;
  }

  async getAllBySportevent(): Promise<ClubTeamResults[]> {
    const response = await HttpService.get<ClubTeamResults[]>(
      `${this.url}bysportevent/${localstore_sportevent.state.sportevent.id}/`
    );
    if (response !== undefined) {
      response.map((i) => new ClubTeamResults(i));
      return response;
    }
    return [];
  }


  async saveList(results: ClubTeamResults[]): Promise<ClubTeamResults[]> {
    const response = await HttpService.put<ClubTeamResults[]>(
      `${this.url}list_update/`,
      results
    );
    if (response !== undefined) {
      response.map((i) => new ClubTeamResults(i));
      return response;
    }
    return [];
  }
}

export default new ClubTeamResultService();
