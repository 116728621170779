import ClubTeamDisciplineResult from "./ClubTeamDisciplineResult";
import SporteventCompetition from "./SporteventCompetition";
import Sportevent from "./Sportevent";
import Society from "../societymgmt/Society";

export default class ClubTeamResults {
  id?: string;
  sportevent: Sportevent = new Sportevent();
  society: Society = new Society();
  clubTeamDisciplineResults: ClubTeamDisciplineResult[] = [];
  totalPoints = 0;

  constructor(result?: ClubTeamResults) {
    if (result === undefined || result === null) {
      return;
    }
    this.id = result.id;
    this.sportevent = result.sportevent
    this.society = result.society
    this.clubTeamDisciplineResults = result.clubTeamDisciplineResults;
    this.totalPoints = result.totalPoints;
  }
}
